.c-side-title {
	font-family:$noto;
	font-size:12px;
	line-height:18px;
	font-weight:700;
	color:$black;
	text-transform:uppercase;
	letter-spacing:1px;
	display:inline-block;
	position: absolute;
    width: auto;
    height: 18px;
    transform: rotate(-90deg);

    @include mq('large') {
    	display: none;
    }
}

.c-side-title:before {
	content:'';
	display:block;
	width:25px;
	height:2px;
	background:$black;
	position:absolute;
	top:0;
	bottom:0;
	left:-35px;
	margin:auto 0;
}

.c-home-banner .c-side-title {
	top: 50px;
    left: -13px;
    bottom: 0;
    margin: auto 0;
}

.home-valeurs .c-side-title{
	top: 85px;
    left: -31px;
    bottom: 0;
}


.home-realisation .c-side-title {
	top: 115px;
    left: -4px;
    bottom: 0;
}