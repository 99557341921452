.c-projects-slider__wrapper {
	padding-right: 60px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	@include mq('bslarge') {
		padding-right:40px;
		flex-flow:column wrap;
	}

	@include mq('medium') {
		display: none;
	}

	.js-projects-slider {

		@include mq('bslarge') {
			margin-left:-25px;
		}
	}

	& > .col-nav {
		min-width: 150px;
		background:$black;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		position:relative;
		padding:30px;

		@include mq('bslarge') {
			margin-top:25px;
			flex-flow:row wrap;
			order:2;
		}

		&:before {
			content:'';
			display: block;
			position:absolute;
			top:20px;
			right:20px;
			bottom:20px;
			left:20px;
			border:1px solid $white;
		}

		button {
			flex-basis: 50%;
			display: flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items: center;
			border: 0px;
			background:transparent;
			font-family: $noto;
			font-size:13px;
			color:$grey;
			font-style: italic;
			-webkit-font-smoothing: antialiased;
			position:relative;
			transition: all 300ms ease-in-out;

			@include mq('bslarge') {
				flex-flow:row wrap;
			}

			&.js-projects-slider-next img {

				@include mq('bslarge') {
					order:2;
				}
			}

			&:hover {
				color:$white;
			}

			&:first-child {

				img {
					transform: rotate(180deg);
				}

				&:before {
					content:'';
					display: block;
					height:1px;
					width:60%;
					position:absolute;
					bottom:0px;
					right:0;
					left:0;
					margin:0 auto;
					background:$grey;

					@include mq('bslarge') {
						top:0px;
						right:0px;
						left:initial;
						margin:auto 0;
						width:1px;
						height:60%;
					}
				}
			}

			&:focus {
				outline: 0;
			}
		}

		img {
			max-width: 15px;
			margin-bottom: 10px;

			@include mq('bslarge') {
				margin:0px 10px;
			}
		}
	}

	& > .col-slider {
		min-width: 0px;

		.c-projects-slider__slide {
			height: 300px;

			@include mq('bslarge') {
				height:200px;
			}

			a {
				display: flex;
				flex-flow:column wrap;
				justify-content: center;
				align-items:center;
				width: 100%;
				height: 100%;
				position:relative;
				padding:40px;
				font-family: $noto;
				font-size:20px;
				color:white;
				font-style:italic;
				text-align: center;
				z-index: 1;
				opacity: 0;
				transition:all 300ms ease-in-out;

				&:before {
					content:'';
					position:absolute;
					top:20px;
					right: 20px;
					bottom:20px;
					left:20px;
					background:rgba(0,0,0,0.7);
					z-index: -1;
				}


				img {
					margin-top:10px;
					max-width: 20px;
					transition:all 300ms ease-in-out;
				}
			}

			&:hover a {
				opacity: 1;

				img {
					margin-right:-20px;
				}
			}
		}
	}

	.c-projects-slider__slide {
		margin-left: 25px;
	    background-repeat: no-repeat;
   		background-size: cover;
		background-position:center;
	}
}