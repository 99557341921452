/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 599px),
	'medium': 		(max-width: 767px),
	'bslarge': 		(max-width: 992px),
	'large': 		(max-width: 1025px),
	'xlarge': 		(max-width: 1199px),
	'xxl': 			(min-width: 1200px)
);


/* =======================================================================
Font-face
========================================================================== */



/* =======================================================================
Variables
========================================================================== */

// Colors
$black:					  #231f20;
$grey:					  #868686;
$white:					  #d8d8d8;
$white-light:			  #f2f2f2;

// Fonts stacks
$font-stack:              Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              #ff0000;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);
$noto:					  'Noto Serif', sans-serif;
$roboto:				  'Roboto Condensed', serif;

// Highlight
$color-selection:         $grey;
$color-placeholder:       $grey;

// Transitions
$fade300:					  all 300ms ease-in-out;
$fade500:					  all 500ms ease-in-out;