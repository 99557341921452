/* =======================================================================
Navigation
========================================================================== */

.menu-main-menu-anglais-container > ul,
.menu-main-menu-container > ul {
	list-style-type:none;
	margin:0;
	padding:0;
}

.menu-main-menu-anglais-container > ul > li,
.menu-main-menu-container > ul > li {
	padding-left:40px;

	@include mq('large') {
		padding-left:25px;
	}
}

.menu-main-menu-anglais-container > ul > li a,
.menu-main-menu-container > ul > li a,
.language-switcher a {
	font-family:$roboto;
	font-size:12px;
	line-height:18px;
	color:$white;
	text-transform:uppercase;
	font-weight:700;
	position:relative;
	transition:$fade300;
}

.menu-main-menu-anglais-container > ul > li a:hover 
.menu-main-menu-container > ul > li a:hover {
	color:white;
}

.menu-main-menu-anglais-container > ul > li a:before,
.menu-main-menu-container > ul > li a:before {
	content:'';
	position:absolute;
	bottom:-3px;
	left:0px;
	display:block;
	width:0px;
	height:1px;
	background:white;
	transition:$fade300;
}
.menu-main-menu-anglais-container > ul > li a:hover:before,
.menu-main-menu-container > ul > li a:hover:before {
	width:20px;
}

.language-switcher {
	margin-left:30px;
	color:$grey;
	-webkit-font-smoothing: antialiased;
}