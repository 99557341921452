.c-quote {
	display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 550px;
    background: rgba(0,0,0,.7);
    padding: 90px;
    position: absolute;
    left: 200px;
    top: 0;
    bottom: 0;
    margin: 100px 0px;

    @include mq('large') {
    	left:0px;
    	right:0px;
    	margin:60px auto;
    	max-width:100%;
    	width: 100%;
    	position:relative;
    	padding:60px;
    }
}

.c-quote:before {
	content:'“';
	position:absolute;
	top:-60px;
	left:80px;
	font-family:$noto;
	font-weight:700;
	font-style:italic;
	font-size:150px;
	color:$white;
	pointer-events:none;

	@include mq('large') {
		left:0;
		right:0;
		text-align: center;
	}
}

.c-quote:after {
	content:'';
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	margin:30px;
	border:1px solid $white;
}

.c-quote p {
	font-family:$noto;
	font-size:20px;
	line-height:32px;
	color:$white;
	font-weight:400;
	font-style:italic;

	@include mq('large') {
		text-align: center;
	}

	@include mq('small') {
		font-size:16px;
		line-height: 28px;
	}
}

.c-quote p.author {
	font-family:$roboto;
	font-size:14px;
	line-height:20px;
	font-style:normal;
}