.padding-cancel {
	
	.row {
		padding:0px 45px;
	}

	.col-md-12 {
		padding:0px;
	
		&.ovhd {
			overflow: hidden;
		}
	}

	.c-details__section-title {
		
		h4 {
			margin:60px 0px 40px;
		}
	}

}


.c-single {

	#c-inner-banner__headline {
		border-bottom:1px solid $black;
		padding-bottom: 60px;
		margin-bottom: 20px;
	}
}

.c-single-slider__wrapper {
	position: relative;

	button {
		position:absolute;
		top:0px;
		bottom:0px;
		margin:auto 0;
		border:0px;
		background:none;
		font-size:0px;
		height:50px;

		&:focus {
			outline:0;
		}

		img {
			width:20px;
			backface-visibility: hidden;
		}

		&.prev {
			left:-45px;

			img {
				transform:rotate(180deg);
			}
		}

		&.next {
			right:-45px;
		}
	}
}

.c-singler-slider__img {
	width:100%;
	max-height:500px;
	height:100%;
	overflow-y:hidden;
	display:flex;
	align-items:center;
	justify-content:center;

	img {
		// width: 100%;
	}
}

.c-single-slider__menu {

	.slick-list {
		padding:0px !important;
	}
}

.c-single-slider__menu-slide {
    padding: 0 10px;
    height: 130px;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin: 10px 10px 0px;

}

.c-single-slider__menu-wrapper {
	margin:20px -10px;
}

.c-single-slider__slide,
.c-single-slider__menu-slide {
	outline:0;
}

.c-single-button {
	text-align:center;
	margin:40px auto 120px;
}

.slick-track {
	display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}