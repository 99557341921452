/* =======================================================================
Footer
========================================================================== */

footer {
	background:$black;
}

footer .container .row {
	padding:80px 0px;
	
	&:nth-child(1) {
		border-bottom:2px solid #353334;
	}

	@include mq('small') {
		padding:60px 0px;
	}

	.flex {

		@include mq('bslarge') {
			justify-content: center;
		}

		.footer-menu {

			@include mq('bslarge') {
				margin-bottom:40px;
			}
		}

		@include mq('small') {
			flex-flow:column wrap;
		}
	}
}

.footer-menu .menu-main-menu-container > ul > li {
	padding-left:0px;
	padding-right:40px;

	@include mq('bslarge') {
		padding-left:10px;
		padding-right:10px;
	}

	@include mq('small') {
		padding-bottom:20px;
	}
}

.footer-menu .menu-main-menu-container > ul > li a {
	color:$grey;

	&:hover {
		color:white;
	}

	&:before {
		display:none;
	}

	@include mq('small') {
		font-size:16px;
	}
}

.footer-contact {

	@include mq(small) {
		display: flex;
		flex-flow:column wrap;
		align-items:center;
		justify-content: center;
	}
}

.footer-contact a {
	font-family:$noto;
	font-weight:400;
	font-size:12px;
	line-height: 16px;
	color:$white;
	margin-left:50px;
	transition:all 300ms ease-in-out;

	&:hover {
		color:white;
	}

	@include mq('small') {
		margin-left:0px;
		margin-bottom:30px;
		font-size:16px;
	}

	&:last-child {

		@include mq('small') {
			margin-top:40px;
		}
	}
}

.footer-scroll-up {
	display:inline-flex;
	flex-flow:column wrap;
	justify-content:center;
	align-items:center;
	width:50px;
	height:50px;
	position:relative;
	border:1px solid #2d2c2c;
	transform:rotate(45deg);

	&:before {
		content:'';
		display:inline-block;
		position:absolute;
		top:6px;
		right:6px;
		bottom:6px;
		left:6px;
		border:1px solid #393838;
	}

	& img {
		transform:rotate(135deg);
		max-width:10px;
	}	
}

.footer-logo {
	max-width:150px;
	width:100%;
	opacity:0.7;
	transition:all 300ms ease-in-out;

	&:hover {
		opacity: 1;
	}
}

.footer-copyright {
	list-style-type:none;
	padding-left:0px;
	margin:0px;
}

.footer-copyright li {
	font-family:$roboto;
	font-size:12px;
	font-weight:400;
	color:$grey;
	width:33.33%;

	&:nth-child(2) {
		text-align: center;
	}

	@include mq('small') {
		width:100%;
		text-align: center;
		padding-bottom:30px;
	}

	&:last-child {

		@include mq('small') {
			flex-flow:row wrap !important;
		}
	}
}

.footer-cc {
	max-width:20px;
	margin-left:5px;
}

.footer-cc-wrapper {
	display:inline-block;
	width:22px;
}
