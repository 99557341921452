.c-button {
	background:$black;
	display:inline-block;
	padding:25px 45px;
	font-family:$roboto;
	font-size: 12px;
	line-height:16px;
	letter-spacing:2px;
	color:$white;
	font-style:normal;
	text-transform:uppercase;
	font-weight:700;
	position:relative;
	transition:all 200ms ease-in-out;

	&:visited {
		color:$white;
	}

	@include mq('small') {
		padding:25px 35px;
	}
}

.c-button:hover {
	color:#fff;
}

.c-button:before {
	content:'';
	border:1px solid $grey;
	background:transparent;
	position:absolute;
	top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    transition:all 300ms ease-in-out;
    pointer-events:none;
    opacity:1;
}

.c-button:hover:before {
	top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border:1px solid $black;
    opacity:0;
}