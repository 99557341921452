.c-inner-banner {
	background-repeat:no-repeat;
    background-position:top -100px center;
	padding:200px 0px 0px;
    position:relative;
    z-index: 0;

	&:before {
		content:'';
		display:block;
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		z-index: -2;
	}

	&:after {
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		display: block;
		background-image:url(../images/line-pattern.png);
		background-repeat:repeat;
		z-index: -1;
	}
}

.c-inner-banner--short {
	@extend .c-inner-banner;
	padding:100px 0px 0px;

	a {
		color:$black;
	}
}

.c-inner-banner .container {
	position:relative;
}

.c-inner-banner .col-md-12 {
	height:100%;
}

#c-inner-banner__headline {
	
	& > div {
		width:50%;
		padding:0px 40px;

		@include mq('small') {
			width: 100%;
			text-align: center;
		}
	}

	@include mq('small') {
		flex-flow:column wrap;
		align-items:center;
		justify-content: center;
	}

	&.contact-banner {
		
		& > div {

			@include mq('bslarge') {
				width:100%;
				text-align: center;
			}
		}
		.c-inner-banner__contact-info > div {
			@include mq('medium') {
				width:100%;
				padding-left:0px !important;
			}
		}
	}
}

#c-inner-banner__headline-full-width {
	
	& > div {
		width:100%;
		padding:0px 40px;

		@include mq('bslarge') {
			padding:0px;
		}

		@include mq('xsmall') {
			text-align: center;
		}
	}
}

#c-inner-banner__headline,
#c-inner-banner__headline-full-width {
	margin-bottom:100px;

	@include mq('xsmall') {
		margin-bottom:60px;
	}


	h3 {
		font-family: $noto;
		font-weight: 700;
		font-size:36px;
		line-height: 44px;
		color:$black;
		margin-top:0px;

		@include mq('medium') {
			font-size:26px;
			line-height: 32px;
		}
	}
	
	h1 {
		font-family: $roboto;
		font-weight:700;
		font-size:15px;
		line-height: 22px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color:$grey;
		position:relative;
		margin-bottom: 10px;

		@include mq('medium') {
			font-size:18px;
			line-height:24px;
		}

		&:before {
			content:'';
			position:absolute;
			top:0;
			bottom:0;
			margin:auto 0;
			left:-90px;
			width: 70px;
			height:1px;
			background:$grey;

			@include mq('bslarge') {
				display: none;
			}
		}
	}

	.c-inner-banner__contact-info {
		padding:0px;
	}

	.c-inner-banner__contact-info > div {
		margin-bottom: 20px;
		width:50%;

		&:nth-child(even) {
			padding-left:40px;
		}
	}

	.c-inner-banner__contact-info  h3 {
		font-family: Noto Serif,sans-serif;
	    font-size: 20px;
	    line-height: 30px;
	    color: $black;
	    font-weight: 400;
	    font-style: italic;
	    text-transform: initial;
	    letter-spacing: 0px;
	    margin-bottom:0px;

	    &:before {
	    	display:none;
	    }
	}

	.c-inner-banner__contact-info {
		
		h3 {
			font-family: Noto Serif,sans-serif;
		    font-size: 20px;
		    line-height: 30px;
		    color: $black;
		    font-weight: 400;
		    font-style: italic;
		    text-transform: initial;
		    letter-spacing: 0px;
		    margin-bottom:0px;

		    &:before {
		    	display:none;
		    }

		    a {
		    	font-size:20px;
		    	line-height: 30px;
		    	color:$black;
		    	border-bottom:1px solid $black;
		    }
		}

		p,
		a {
			margin-top:0px;
			font-family: Noto Serif,sans-serif;
		    font-size: 15px;
		    line-height: 22px;
		    color: $grey;
		    font-weight: 400;
		    font-style: italic;
	    }
	}
}



#c-inner-banner__headline--full-width {
	@extend #c-inner-banner__headline;
	
	& > div {
		width:100%;
	}
}

.c-inner-banner__sub-title {
	font-family: $noto;
	font-weight:400;
	font-style:italic;
	font-size: 20px;
	line-height: 30px;
	color:$grey;
	-webkit-font-smoothing: antialiased;

	a {
		border-bottom:2px solid $black;
	}

	@include mq('medium') {
		font-size:16px;
		line-height: 24px;
	}
}

#c-inner-banner__page-nav {
	padding:0px 55px 40px;
	border-bottom:1px solid $grey;

	@include mq('bslarge') {
		margin:0px 30px;
		justify-content: center;
		padding:0px 20px 40px;
	}

	@include mq('xsmall') {
		flex-flow:column wrap;
	}

	a {
		margin-right:40px;
		font-family:$roboto;
		font-weight:700;
		font-size:13px;
		line-height: 18px;
		text-transform: uppercase;
		letter-spacing: 1px;
		color:$grey;
		transition:all 300ms ease-in-out;

		@include mq('bslarge') {
			margin-right:20px;
			margin-left:20px;
		}

		@include mq('xsmall') {
			font-size:16px;
			margin-bottom:30px;
		}

		&:hover {
			color:$black;
		}
	}
}

.c-inner-banner__filter {
	list-style-type: none;
	padding:0px;
	display:flex;
	flex-flow: row wrap;
	justify-content:flex-start;
	align-items:center;

	@include mq('xsmall') {
		flex-flow:column wrap;
	}

	h2 {
		margin:0px;
		font-style:initial;
		position:relative;

		a {
			color:$black;

			@include mq('bslarge') {
				font-size:30px;
				line-height: 35px;
			}
		}
	}

	h3 {
		font-family: $roboto;
		font-weight:700;
		font-size:20px;
		line-height: 26px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color:$grey;
		position:relative;
		margin-bottom: 10px;
	}

	li {
		margin-right:20px;

		@include mq('xsmall') {
			margin-right:0px;
		}

		&.plus {
			font-size:24px;
			font-weight:700;
		}

		&.all {
			
			@include mq('medium') {
				padding-left:0px;
				margin-top:60px;
			}
		}

		&.all a {
			color:$black;
			font-family: $noto;
			font-size:14px;
			font-weight: 700;
			margin-left:40px;
			position: relative;

			@include mq('medium') {
				margin-left:0px;
			}

			&:before {
				content:'';
				position:absolute;
				bottom:-10px;
				left:0;
				right:0;
				height:2px;
				width:0px;
				background:$black;
				transition:all 300ms ease-in-out;
				opacity: 0;
			}

			&:hover:before {
				width:30px;
				opacity: 1;
			}
		}

		h2:before {
			content:'';
			position:absolute;
			bottom:-20px;
			left:0;
			right:0;
			width:0px;
			height:2px;
			background:$black;
			transition:all 300ms ease-in-out;
			opacity: 0;
		}

		&:hover h2:before,
		&.active h2:before {
			opacity: 1;
			width:30px;
		}

		&.active.all a:before{
			opacity: 1;
			width:30px;
		}
	}
}

.about-menu {

	a:first-child {
		@include mq('medium') {
			display: none;
		}
	}
}