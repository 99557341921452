#scroll-historique {

	@include mq('medium') {
		display: none;
	}
}

.c-history-slider:focus {
	outline:0;
}

.c-history-slider .slick-arrow {
	border:0px;
	font-size:0px;
	display: block;
	width: 20px;
	height: 20px;
	background:url('../images/historique-arrow.png');
	background-size:contain;
	background-repeat:no-repeat;
	content:'';
	position:absolute;
	top:0;
	bottom:0;
	z-index:9999;
	margin:auto 0;

	&.slick-prev {
		left:0px;
		transform:rotate(180deg);
	}

	&.slick-next {
		right:0px;
	}

	&:focus {
		outline:0;
	}
}

.c-history-slider__slide {
	position:relative;
	height:500px;
	display:flex !important;
	flex-flow:column wrap;
	justify-content:center;
	align-items:center;


	.background-year {
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		margin:auto;
		display:flex;
		justify-content: center;
		align-items: center;
		z-index: -1;
		font-family: $roboto;
		font-size:500px;
		font-weight:700;
		color:rgba(0,0,0,0.03);

		@include mq('xlarge') {
			font-size:400px;
		}


		@include mq('bslarge') {
			font-size:300px;
		}
	}

	h2 {
		font-style:initial;
		margin:0px auto;
		max-width: 800px;
		text-align:center;
		font-size: 36px;
		line-height: 42px;
	}

	h3 {
		font-family: $noto;
		font-size:20px;
		line-height: 26px;
		margin-bottom:0px;
	}

	&:focus {
		outline:0;
	}
}

.c-history-slider__button {
	border:0px;
	background:none;
	font-family:$noto;
	font-size:20px;
	font-weight:bold;
	color:$white;
	-webkit-font-smoothing: antialiased;
	transition:all 300ms ease-in-out;

	&:focus {
		outline:0;
	}

	&.slick-current {
		color:$black;
	}
}

.js-history-slider-menu {

	.slick-track {
		transform:none !important;
		margin: 0 auto;
	}
}