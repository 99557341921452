.col-md-12.c-equipe{
	width: 100%;
	@media(max-width: 992px){
		width: 100%;
	}
}

.c-equipe{
	margin-bottom: 20px;

	&__member{
		margin-bottom: 40px;

		@media(max-width: 992px){
			width: 50%;
		}

		@media(max-width: 768px){
			margin-left: auto;
			margin-right: auto;
		}

		@media(max-width: 580px){
			width: 80%;
		}

		@media(max-width: 360px){
			width: 100%;
		}

		.img-member{
			height: 340px;

			@media(max-width: 1200px){
				height: 300px;
			}

			@media(max-width: 992px){
				height: 350px;
			}
		}

		.infos-member{
			margin-top: 15px;
			text-align: center;

			p:first-child, p:last-child{
				margin: 0;
				
				color: $black;
				font-family: $noto;
				font-size: 18px;
				font-style: italic;
			}

			p:last-child{
				color: $grey;
				font-size: 14px;
			}
		}
	}
}