/* =======================================================================
c-banner
========================================================================== */

.c-banner {
	height: 500px;
}

.c-banner__title {
	text-align: center;
}