.partners-list {
	list-style-type:none;
	padding:0px;
	margin:0px;

	li {
		width:30%;
		height: 280px;
		text-align:center;
		background:#f8f8f8;
    	margin: 15px;
    	display:flex;
    	flex-flow:row wrap;
    	justify-content: center;
    	align-items:center;
    	position:relative;

    	@include mq('bslarge') {
    		width:45%;
    	}

    	@include mq('medium') {
    		width:100%;
    	}
		
		a {
			width:100%;
			height:100%;
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:center;
			cursor:pointer;

			&:before {
				content:'';
				position:absolute;
				top:20px;
				right:20px;
				bottom:20px;
				left:20px;
				border:1px solid $white;
				transition:all 300ms ease-in-out;
				opacity:1;
			}

			&:hover:before {
				top:0px;
				right:0px;
				bottom:0px;
				left:0px;
				opacity:0;
			}
		}

    	img {
    		max-width:150px;
    		margin:30px;
    	}
	}
}