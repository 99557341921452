.c-home-banner {
	background-repeat:no-repeat;
    background-position: top -50px center;
    min-height:100%;
    position:relative;

    .row-banner {
    	padding:0px 40px;
    }
}

.c-home-banner .container-banner {
	position:relative;

	&:before,
	&:after {
		position:absolute;
	}

	.row:before,
	.row:after {
		position:absolute;
	}
}

.c-home-banner .c-home-banner__wrapper {
	height:100%;
	margin-bottom:50px;

	@include mq('bslarge') {
		margin-bottom: 50px;
	}
}

.c-home-banner .c-home-banner__wrapper img {
	margin-top: 150px;
	margin-bottom:80px;
    max-width: 840px;
    backface-visibility: hidden;
    z-index: -10;

    @include mq('large') {
    	width: 100%;
    }
}

.c-home-banner .circle {
	position:absolute;
	font-family:$roboto;
	font-size:12px;
	line-height:12px;
	color:$white;
	text-transform:uppercase;
	font-weight:700;
	background:$black;
	width:50px;
	height:50px;
	display:flex;
	flex-flow:column wrap;
	justify-content:center;
	align-items:center;
	border-radius:50%;

	@include mq('large') {
		display: none;
	}
}

.c-home-banner .circle:before {
	content:'';
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:-25px;
	width:99px;
	height:99px;
	border-radius:50%;
	margin:auto;
	background:transparent;
	border:2px dotted $grey;
	z-index: -15;
}
.c-home-banner .circle:after {
	content:'';
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:-12.5px;
	width:75px;
	height:75px;
	border-radius:50%;
	margin:auto;
	background:$white;
	z-index: -15;
	opacity:0;
	transform:scale(0.75);
	transition:all 300ms ease-in-out;
}
.c-home-banner .circle:hover:after {
	opacity:1;
	transform:scale(1);
	
}

.c-home-banner .circle:hover:before {
	animation: spin 8s infinite;
	animation-timing-function:linear;
}

.c-home-banner .circle.one {
	left: 130px;
    top: 150px;

}
.c-home-banner .circle.two {
    top: 128px;
    left: 695px;
}