.c-404 {
	text-align:center;
	padding:100px 40px;

	& > * {
		font-family: $roboto;
		text-transform: uppercase;
		font-weight:bold;
	}

	&__error {
		background:$black;
		color:white;
		display: inline-block;
		padding:20px 40px;
		font-size:30px;
		position:relative;
		margin-bottom:20px;

		&:before {
			content:'';
			position:absolute;
			top:10px;
			right:10px;
			bottom:10px;
			left:10px;
			border:1px solid $white;
		}
	}

	&__title {
		font-size:250px;
		line-height: 250px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color:$black;

		@include mq('xsmall') {
			font-size:150px;
			line-height: 150px;
		}
	}

	p {
		font-size:30px;
		margin-top:0px;
		font-family: $noto;
		text-transform: initial;

		@include mq('xsmall') {
			font-size:20px;
			line-height: 20px;
		}

	}
}