.c-header {
	height:60px;
	background:$black;
	position:relative;
    z-index: 9999;

    .row:before,
    .row:after {
        @include mq('large') {
            display: none;
        }
    }

    &__wrapper {
        display: flex !important;
        width:100%;
    }
}

.c-header__logo {
    
    &:before {
        @include mq('large') {
            display: none;
        }
    }
}

.c-header__logo a {
    display: block;
    width:100%;
    height: 100%;
    text-align:center;
    padding: 10px;
    position: relative;
    z-index: 2;
}

.c-header__logo img {
	max-width:45px;
}

.c-header .row {
	width:100%;
}

.c-header__logo-background {
	content: '';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 80px;
    top: -30px;
    left: 50px;
    background: #231f20;
    z-index: 9999;

    @include mq('large') {
        left:30px;
    }
}

.c-header__logo-background:before {
	content: '';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 75px;
    top: -10px;
    right: 0;
    bottom: 0;
    left: 0;
    margin:auto;
    background: transparent;
    border:1px solid $grey;
}