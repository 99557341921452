.c-details {

	&__section-title h4 {
		font-family: $roboto;
	    font-weight: 700;
	    font-size: 16px;
	    line-height: 24px;
	    color: #868686;
	    text-transform: uppercase;
	    letter-spacing: 4px;
	    margin-bottom: 80px;
	    margin-top:120px;
		
		@include mq('bslarge') {
			text-align: center;
		}
	}

	&__section-title.nmb h4 {
		margin-bottom: 0px;
	}

	h2 {
	   margin-top:0px;
	   font-size:45px;
	   line-height: 50px;
	   position:relative;
	   z-index: 10;

	   @include mq('xlarge') {
	   	font-size:35px;
	   	line-height: 40px;
	   }

	   @include mq('bslarge') {
   		   	margin-top:40px;
	   		text-align: center;
	   		font-size:30px;
	   		line-height: 35px;
	   }
   	}

 	h3 {
		font-family: $noto;
		font-size:20px;
		line-height:30px;
		color:$black;
		font-weight:400;
		font-style:italic;

		@include mq('bslarge') {
			text-align: center;
			font-size:18px;
		}
   	}

   	p {
		font-family: $noto;
		font-size:14px;
		line-height:23px;
		color:$grey;
		font-weight:400;
		font-style:italic;
		-webkit-font-smoothing: antialiased;

		@include mq('bslarge') {
			text-align: center;
		}
   	}

   	.title-right h2 {
		margin-left:-160px;

		@include mq('bslarge') {
			margin-left:0px;
		}
	}

	.title-right--large {
		@extend .title-right;
		margin-left:-250px;
		
		@include mq('bslarge') {
			margin-left:0px;
		}
	}

	.title-left h2 {
		margin-right:-160px;

		@include mq('bslarge') {
			margin-right:0px;
		}
	}

	.title-left--large {
		@extend .title-left;
		margin-right:-250px;

		@include mq('bslarge') {
			margin-right:0px;
		}
	}
}

.c-details--paragraph {
	@extend .c-details;
	
	@include mq('bslarge') {
		text-align: center;
	}

	h2 {
		margin-bottom:30px;
	}

	h3 {
		margin-bottom:30px;
	}

	p {
		margin-bottom:40px;
	}
}

.c-details--list {
	@extend .c-details;

	h2 {
		margin-bottom:30px;
	}

	h3 {
		margin-bottom:10px;
	}

	p {
		margin-bottom:20px;
	}
}

.c-details--team {
	@extend .c-details;

	h2 {
		margin-top:-30px;
		margin-bottom:30px;

		@include mq('bslarge') {
			margin-top:30px;
		}
	}

	h3 {
		margin-top:5px;
		margin-bottom:0px;
	}

	p {
		margin-top:0px;
		margin-bottom:10px;
	}

	.c-details__subtitle {
		padding-left:30px;

		@include mq('bslarge') {
			padding-left:0px;
		}

		&:last-child {
			@include mq('bslarge') {
				margin-bottom:30px;
			}
		}
	}
}

.c-details__section-title--nm-top {
	@extend .c-details__section-title;
	
	h4 {
		margin-top:0px;
		margin-bottom:40px;
	}
}

.inner-page-padding {

	@include mq('bslarge') {
		justify-content: center;
		padding-left:30px;
		padding-right:30px;
	}
}

#scroll-commercial .flex > .col-md-6:first-child {

	@include mq('bslarge') {
		order:2;
	}
}

#scroll-equipe {

	.row {

		@include mq('bslarge') {
			display: flex;
			flex-flow:column wrap;
		}
		
		.c-details--team {

			@include mq('bslarge') {
				order:3;
			}
		}
	}
}