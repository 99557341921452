.c-contact-form {
	max-width:950px;
	margin:0 auto;

	@include mq('medium') {
		padding:0px 30px;
	}

	& > div {
		display:inline-block;
		width:48%;
		margin:0px;
		margin-top:40px;

		@include mq('xsmall') {
			width:100%;
		}

		&:last-child {
			width:100%;
		}

		input,
		textarea {
			width:100%;
			border-top:0px;
			border-right:0px;
			border-left:0px;
			border-bottom:1px solid $grey;
			font-family:$noto;
			font-weight:400;
			font-size:15px;
			line-height:24px;
			-webkit-font-smoothing: antialiased;

			&:focus {
				outline:0;
			}
		}

		input {
			height:50px;
		}

		textarea {
			height:150px;
		}
	}
}

.c-contact-button__wrapper {
	text-align:center;
	margin:50px 0px 0px;

	.ajax-loader {
		display:none !important;
	}

	input {
		background:$black;
		display:inline-block;
		padding:25px 45px;
		font-family:$roboto;
		font-size: 12px;
		line-height:12px;
		letter-spacing:2px;
		color:$white;
		font-style:normal;
		text-transform:uppercase;
		font-weight:700;
		position:relative;
		transition:all 200ms ease-in-out;
		border:0px;

		&:hover {
			color:white;
		}
	}

	.c-contact-button {
		position:relative;
		display:inline-block;

		&:before {
			content:'';
			border:1px solid $grey;
			background:transparent;
			position:absolute;
			top: 5px;
			right: 5px;
			bottom: 5px;
			left: 5px;
			transition:all 300ms ease-in-out;
			pointer-events:none;
			opacity:1;
			z-index: 1;
		}

		&:hover:before {
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			border:1px solid $black;
			opacity:0;
		}
	}	
}

span.wpcf7-not-valid-tip {
	font-family:$noto;
	font-size:12px;
	line-height:18px;
	margin-top:5px;
}

.wpcf7-response-output,
.wpcf7-mail-sent-ok {
	border:0px;
	font-family:$noto;
	font-size:14px;
	line-height:18px;
	text-align:center;
}

div.wpcf7-validation-errors {
	border:0px;
	color:#f00;
	margin-top:50px;
}

.wpcf7-submit:focus {
	outline:0;
}

.wpcf7-mail-sent-ok {
	color:#398f14;
	border:0px !important;
}