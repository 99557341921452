.c-blackbox {
	background:$black;
	position:relative;
	margin:100px auto 0px;
	padding:150px 50px;

	@include mq('small') {
		padding:100px 50px;
	}

	h2 {
		margin-top:0px;
		color:white;
		font-style:initial;

		@include mq('small') {
			font-size:30px;
			line-height:35px;
			text-align: center;
		}
	}

	p {
		max-width:600px;
		margin:0 auto;
		text-align:center;
		font-family: $noto;
		font-weight:400;
		font-style:italic;
		color:$grey;
		font-size:22px;
		line-height:32px;

		@include mq('small') {
			font-size:16px;
			line-height: 24px;
			text-align: center;
		}
	}
	
	&:before {
		content:'';
		display: block;
		position:absolute;
		top:30px;
		right:30px;
		bottom:30px;
		left:30px;
		border:1px solid $white;
		z-index:999;
	}

}