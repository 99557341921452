.c-section-scroll {
	background: $black;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 41px;
    bottom: 0px;
    transform:rotate(45deg);

    a {
    	display: block;
    	width:100%;
    	height: 100%;
    }

    @include mq('bslarge') {
    	left:0;
    	right:0;
    	margin:0 auto;
    	bottom:-50px;
    }
}

.c-section-scroll:before {
	content:'';
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	width:40px;
	height:40px;
	border:1px solid $grey;
	pointer-events:none;
}

.c-section-scroll img {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	transform:rotate(-45deg);
	width:12px;
}