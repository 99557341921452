.c-mobile-nav {
	position: fixed;
	top: 0px;
	left: 0px;
	transform: translate(-100%, 0px);
	background: #000;
	width: 100%;
	max-width: 400px;
	height: 100%;
	transition: all 300ms ease-out;
	z-index: 9999999;
	padding:30px;
	
	&.active {
		transform: translate(0%, 0px);
	}
}

.js-menu-button {
	display:none;

	@include mq('bslarge') {
		display: block;

		.fa.fa-bars {
			color:white;
		}
	}

}

#menu-main-menu,
.language-switcher {
	@include mq('bslarge') {
		display:none!important;
	}
}

.js-mobile-nav {
	overflow:hidden;

	&:before {
		content:'';
		background-image:url(../images/line-pattern.png);
		background-repeat:repeat;
		display:block;
		height:100%;
		width:100%;
		position:absolute;
		transform:rotate(45deg);
		opacity: 0.15;
		pointer-events: none;
		width:150%;
		height:150%;
		top:-100px;
	}

	.language-switcher {
		@include mq('bslarge') {
			display:block;
		}
	}

	.menu-main-menu-container {
		height:100%;

		ul {
			display:flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items: flex-start;
			height: 100%;

			li {

				a {
					font-size:20px;
					line-height: 26px;
					margin-bottom:20px;
					display: block;
				}

				&.current-menu-item a:before {
					width:20px;
				}
			}
		}
	}
}

.js-menu-button.close {
    position: absolute;
    right: 30px;
    background: transparent;
    color: #fff;
    border:0px;

    &:focus {
		outline:0;
    }
}

.mobile-nav-phone {
	margin-top:-40px;
	font-family:$roboto;
	font-weight:bold;
	color:$white;
	text-transform:uppercase;
	font-size:12px;
	-webkit-font-smoothing: antialiased;
	padding-left:25px;

	a {
		color:white;
	}
}