.c-project-listing__residentiel,
.c-project-listing__commercial,
.c-project-listing__mixed {
	@extend .c-project-listing;
}

.c-project-listing__residentiel {

	li {
		width:32%;

		@include mq('medium') {
			width: 100%;
			margin:0px 30px;
		}
	}

	a {
		min-height:280px;

		@include mq('xsmall') {
			min-height:250px;
		}

		h2 {
			font-size:30px;
			line-height: 35px;

			&:before {
			    top: 20px;
			}
		}

		h3 {
			font-size:14px;
			line-height: 24px;
		}
	}
}

.c-project-listing__commercial {

	li {
		width:100%;

		@include mq('medium') {
			margin:0px 30px;
		}

		a {
			min-height:400px;

			h2 {
				font-size:45px;
				line-height: 50px;

				@include mq('medium') {
					font-size:30px;
					line-height: 35px;
				}

				&:before {
			    	top: 25px;
				}
			}

			h3 {
				font-size:18px;
				line-height: 24px;
			}
		}
	}
}


.c-project-listing__mixed {

	li {

		&.residentiel {
			width:32%;

			@include mq('medium') {
				width:100%;
				margin:0px 30px 30px;
			}
		}

		&.commercial {
			width:66%;

			@include mq('medium') {
				width:100%;
				margin:0px 30px 30px;
			}
		}

		a {
			min-height:280px;

			h2 {
				font-size:30px;
				line-height: 35px;

				&:before {
			    	top: 20px;
				}
			}

			h3 {
				font-size:14px;
				line-height: 24px;
			}
		}
	}
}


.c-project-listing {
	
	ul {
		padding:0;
		margin:0;
		list-style-type:none;

		li {
			margin-bottom:25px;
			overflow: hidden;
		}

		li > a {
			display: flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items: flex-start;
			text-align: left;
			padding:40px;
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center;
			position: relative;
			z-index: 1;

			@include mq('bslarge') {
				justify-content: center;
				align-items:center;
			}

			&:before {
				content:'';
				position:absolute;
				top:20px;
				right:20px;
				bottom:20px;
				left:20px;
		    	opacity: .7;
			    z-index: -1;
			    pointer-events: none;
			    background: -webkit-linear-gradient(top,rgba(0,0,0,.3),#000);
			    background: linear-gradient(180deg,rgba(0,0,0,.3) 0,#000);
			    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d000000",endColorstr="#000000",GradientType=0);
			    -webkit-transition: all .3s ease-in-out;
			    transition: all .3s ease-in-out;
			}

			.button-realisation {

				@include mq('bslarge') {
					display: none;
				}
			}

			h2,
			h3 {
				margin:0px;
				color:white;

				@include mq('bslarge') {
					text-align: center;
				}
			}

			h2 {
				position:relative;

				&:before {
				    content: "";
				    display: block;
				    width: 50px;
				    height: 2px;
				    background: white;
				    position: absolute;
				    left: -60px;
				    transition: all .3s ease-in-out;

				    @include mq('bslarge') {
				    	display: none;
				    }
				}
			}

			&:hover {

				h2 {

					&:before {
						width: calc(100% + 65px);
					}
				}

				&:before {
					opacity: 0.8;
				}
			}

			h3 {
				font-family:$roboto;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			&:hover .button-realisation {
				width:60px;

				img {
					opacity: 1;
				}
			}
		}
	}
}

.bottom-spacing {
	margin-bottom:120px;
}