html, body {
	height:calc(100% - 60px);
	position:relative;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	@include breakpoint-label;
	background-color:white;
	background-image:url(../images/line-pattern.png);
	background-repeat:repeat;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; text-decoration:none; }
	&:focus,
	&:active { color: $color-link-active; text-decoration:none; }
}

h1, h2, h3, h4, h5, h6, p, a {
	-webkit-font-smoothing: antialiased;
}

h2 {
	font-family:$noto;
	font-size:45px;
	line-height:50px;
	color:$black;
	font-weight: 700;
	font-style:italic;
}

.grey {
	color:$grey;
}


img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: $color-selection;
	text-shadow: none;
	color:white;
}

::selection {
	background: $color-selection;
	text-shadow: none;
	color:white;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $color-placeholder;
	opacity: 1;
	font-weight:400;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $color-placeholder;
	opacity: 1;
	font-weight:400;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $color-placeholder;
	opacity: 1;
	font-weight:400;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $color-placeholder;
	opacity: 1;
	font-weight:400;
}

// Flex-box

.flex {
	display:flex !important;

	&.row {
		display: flex !important;
	}
}

.rw {
	flex-flow:row wrap;
}

.rnw {
	flex-flow:row nowrap;
}

.cw {
	flex-flow:column wrap;
}

.cnw {
	flex-flow:column nowrap;
}

.jsb {
	justify-content:space-between;
}

.jsa {
	justify-content:space-around;
}

.jc {
	justify-content:center;
}

.jfe {
	justify-content:flex-end;
}

.ac {
	align-items:center;
}

.afs {
	align-items:flex-start;
}

// Full width
.full-width-img img {
	width:100%;
}

// Max-width
.mw-900 {
	max-width:900px;
	margin:0 auto;
}

// Gutter
.gutter {
	padding-left:130px;

	@include mq('large') {
		padding-left:40px;
	}
}

// Inner pages padding
.inner-page-padding {
	padding-left:55px;
	padding-right:55px;
}

// Last element margin bottom
.last-element {
	margin-bottom:120px;
}

// Section spacer
.section-spacer {
	margin-bottom:120px;

	@include mq('bslarge') {
		margin-bottom:60px;
	}
}

// Text alignment
	.text-align-right {
		text-align:right;
	}

