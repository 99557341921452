.logo-description__element {
	padding:0px 40px;
	position:relative;

	&:before {
		font-family: Roboto Condensed,serif;
		font-size: 240px;
		line-height: 240px;
		font-weight: 700;
		color: rgba(0,0,0,0.07);
		position: absolute;
		top: -40px;
		bottom: 0;
		left: -40px;
		z-index: -1;
		transition:all 300ms ease-in-out;

		@include mq('bslarge') {
			left:0px;
			right:0px;
		}
	}

	&.active:before {
		color: rgba(0,0,0,0.12);
	}

	&:nth-child(1):before {
		content: "01";
	}

	&:nth-child(2):before {
		content: "02";
	}

	@include mq('bslarge') {
		text-align:center;
		margin-bottom: 150px;
		padding:0px 0px;

		p {
			max-width: 600px;
			margin: 0px auto;
		}
	}
}

.logo-description__element h3 {
	font-family:$noto;
	font-size:20px;
	line-height:28px;
	font-weight:700i;
	color:$black;
	position:relative;
	display:inline-block;

	&:before {
		content: "";
		position: absolute;
		left: -22px;
		height: 2px;
		width: 15px;
		top: 13px;
		background: #231f20;
		transition: all 300ms ease-in-out;
		
		@include mq('bslarge') {
			right:0;
			left:0;
			margin:0 auto;
			bottom:-10px;
			top:initial;
		}
	}
}

.logo-description__element.active h3:before {
	width:45px;
}

.logo-description__element p {
	font-family:$noto;
	font-size:14px;
	line-height:22px;
	font-weight:400;
	font-style:italic;
}

.home-valeurs {
	margin-top:200px;
	background-position: left 130px top;
	background-repeat:no-repeat;
	background-size:cover;
	min-height:600px;
	position:relative;

	@include mq('large') {
		background-position: center center;
		padding:0px 40px;
		min-height:auto;
	}

	@include mq('xsmall') {
		padding:0px 20px;
	}
}

.home-valeurs h2 {
	margin-top:-30px;
	margin-left:200px;

	@include mq('large') {
		margin-left:initial;
		text-align: center;
	}

	@include mq('medium') {
		font-size:36px;
	}

	@include mq('small') {
		display: none;
	}
}

.home-triangle-bg {
	position:absolute;
	bottom:-120px;
	opacity:0.1;
	left:-140px;
	z-index: -1;

	@include mq('large') {
		display: none;
	}
}

.home-triangle-bg img {
	max-width: 500px;
	backface-visibility:hidden;
	opacity:0.3;

	@include mq('medium') {
		width:100%;
		max-width: 90%;
	}
}

.home-one-third-boxes {
	font-family:$noto;
	font-size:24px;
	line-height:30px;
	color:$black;
	font-weight:400;
	font-style:italic;
	padding-top:60px;
	padding-bottom:120px;
	text-align:center;
}

.home-one-third-boxes ul {
	list-style-type: none;
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	padding:0px;
	max-width:800px;
	margin:0 auto 30px;

	@include mq('medium') {
		flex-flow:column wrap;
	}
}

.home-one-third-boxes li {
	text-align:left;

	@include mq('medium') {
		text-align: center;
	}
}

.home-realisation {
	position:relative;
	padding-bottom:25px;
}

.home-realisation .container-fluid {
	padding-right:60px;
	overflow:hidden;

	@include mq('large') {
		padding-right:40px;
	}
}

.realisation-row {
	margin-bottom:25px;

	&:last-child {
		margin-bottom:0px;
	}
}

.box {
	padding:20px 60px 20px;
	background-size:cover;
	position:relative;

	@include mq('medium') {
		align-items: center;
	}

	@include mq('small') {
		padding:20px 40px;
	}
	
	&:hover {
		cursor:pointer;
	}

	&:before {
		content:'';
		position:absolute;
		right:20px;
		bottom:20px;
		left:20px;
		opacity:0.7;
		z-index:0;
		pointer-events:none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#000000',GradientType=0 );
		transition:all 300ms ease-in-out;

		@include mq('xsmall') {
			right:15px;
			bottom:15px;
			left:15px;
		}
	}

	&:hover:before {
		opacity:0.8;
	}
}

.box.two-third:before {
	top:54px;

	@include mq('xsmall') {
		top:43px;
	}
}

.box.two-fifth:before,
.box.three-fifth:before {
	top:20px;

	@include mq('xsmall') {
		top:15px;
	}
}

.box-one-third {
	width:35%;
	padding:0px 40px 0px 15px;
	position:relative;
	min-width:420px;

	@include mq('bslarge') {
		width:100%;
		padding:0px 40px 0px 40px;
		min-width:100%;
	}

	@include mq('small') {
		padding:0px 0px;
	}

	h2 {
		margin:0px;

		@include mq('bslarge') {
			text-align: center;
		}

		@include mq('small') {
			font-size:35px;
			line-height: 40px;
		}
	}

	.c-button__wrapper {

		@include mq('bslarge') {
			text-align: center;
			margin:30px 0px 60px;
		}
	}
}

.box-one-third p {
	font-family:$noto;
	font-size:18px;
	line-height:28px;
	font-style:italic;
	color:$grey;
	max-width:300px;
	margin:30px auto 30px 0;

	@include mq('bslarge') {
		text-align: center;
		margin:30px auto;
		max-width: 500px;
	}

	@include mq('small') {
		margin-bottom:10px;
	}
}

.box.two-third {
	width:auto;
	flex-grow:1;
	padding:45px 60px 20px;
	min-height:400px;
	background-position:top center;

	@include mq('small') {
		width:100%;
		min-height:320px;
		padding:45px 40px 20px;
	}

	@include mq('xsmall') {
		min-height: 290px;
	}
}

.two-fifth,
.three-fifth {
	min-height:350px;
	background-position:center;

	@include mq('bslarge') {
		width:48% !important;
	}

	@include mq('medium') {
		width:100% !important;
		margin-bottom: 25px;
	}

	@include mq('small') {
		min-height:320px;
	}

	@include mq('xsmall') {
		min-height: 250px;
	}

	&.box h2 {
		
		@include mq('bslarge') {
			font-size:30px;
			line-height: 35px;
		}

		@include mq('medium') {
			text-align: center;
		}

		@include mq('xsmall') {
			font-size: 25px;
			line-height: 30px;
		}
	}

	.button-realisation {

		@include mq('bslarge') {
			display: none;
		}
	}
}

.two-fifth {
	width:40%;
}

.three-fifth {
	width:58%;
}


.box h2,
.box h3 {
	margin:0px;
	position:relative;
	z-index: 2;
}

.box h2 {
	font-family:$noto;
	font-size:45px;
	line-height:50px;
	font-weight:400;
	color:white;
	font-style:italic;

	@include mq('xsmall') {
		font-size:30px;
		line-height: 35px;
	}

	&:before {
	    content: "";
	    display: block;
	    width: 50px;
	    height: 2px;
	    background: white;
	    position: absolute;
	    top: 30px;
	    left: -60px;
	    transition:all 300ms ease-in-out;

	    @include mq('bslarge') {
	    	display: none;
	    }
	}
}

.box h3 {
	font-family:$roboto;
	font-size:14px;
	color:white;
	text-transform:uppercase;
	letter-spacing:1px;

	@include mq('xsmall') {
		font-size:12px;
	}
}

.box:hover h2:before {
    width:calc(100% + 65px);
}

.button-realisation {
	width:20px;
	height:60px;
	position:absolute;
	right:0px;
	top:0px;
	bottom:0px;
	margin:auto 0;
	background:black;
	transition:all 300ms ease-in-out;
	display:flex;
	flex-flow:column wrap;
	justify-content:center;
	align-items:center;
}

.box:hover .button-realisation {
	width:60px;
}

.button-realisation img {
	opacity:0;
	transition:all 300ms ease-in-out;
	max-width:15px;
}

.box:hover .button-realisation img {
	opacity:1;
}

.two-third .button-realisation {
	top:25px;

		@include mq('bslarge') {
			display: none;
		}
}

#home-partners {
	padding-top:80px;
	padding-bottom:120px;

	@include mq('bslarge') {
		padding-top:0px;
	}

	h2 {

		@include mq('xlarge') {
			font-size:40px;
		}

		@include mq('bslarge') {
			text-align: center;
		}

		@include mq('small') {
			font-size:30px;
			line-height: 35px;
		}

	}

	.col-md-4 {

		@include mq('bslarge') {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			padding: 0px 40px;
		}

		@include mq('medium') {
			flex-flow:column wrap;
			margin-bottom:80px;
		}
	}

	&.gutter {

		@include mq('bslarge') {
			padding-left:20px;
		}
	}

	.col-md-8 {

		@include mq('bslarge') {
			padding:0px 40px;
		}
	}
}

.partners-logo {
	list-style-type:none;

	@include mq('bslarge') {
		padding-left:0px;
	}
	
	li {

		@include mq('bslarge') {
			text-align: center;
		}

		@include mq('medium') {
			width: 50% !important;
		}

		@include mq('small') {
			width: 100% !important;
		}
	}
}

.partners-logo li {
	width:25%;
	margin:25px 0px;
}

.partners-logo li img {
	max-width:110px;
	width:100%;

	@include mq('medium') {
		max-width: 150px;
		margin-bottom:40px;
	}
}